import React, { useState, useEffect } from "react";
import "./Products.css";
import ProductLoop from "./components/ProductLoop";

function FeaturedProducts() {
  const [products, setProducts] = useState([]);
  const [paged, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [loading, setLoading] = useState(true);
  const wpProductFilter = window.wpProductFilter;
  const wpProductCategory = window.wpProductCategory;
  useEffect(() => {
    fetchProducts();
  }, [paged]);

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      category: wpProductCategory.category,
      paged,
      limit: itemsPerPage,
      featured: "Yes",
    });

    try {
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      setProducts(data.products_group_by);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {Object.keys(products).length > 0 && (
        <div className="product-list-container">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div className="product-plp-grid product-grid swatch special_product_grid">
              {Object.keys(products).map((product, i) => {
                return (
                  <ProductLoop key={i} products={products[product]} rows={4} />
                );
              })}
            </div>
          </div>
        </div>
      )}

      {Object.keys(products).length == 0 && loading == false && (
        <div className="product-list-container">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <h2>No Products Available</h2>
          </div>
        </div>
      )}
    </>
  );
}

export default FeaturedProducts;
