import React, { useState, useEffect } from "react";
import "./Products.css";
import ProductLoop from "./components/ProductLoop";
import CollectionFacet from "./components/facets/CollectionFacet";
import BrandFacet from "./components/facets/BrandFacet";
import ColorFacet from "./components/facets/ColorFacet";
import ApplicationFacet from "./components/facets/ApplicationFacet";
import ColorVariationFacet from "./components/facets/ColorVariationFacet";
import ConstructionFacet from "./components/facets/ConstructionFacet";
import InstallationMethodFacet from "./components/facets/InstallationMethodFacet";
import LocationFacet from "./components/facets/LocationFacet";
import SpeciesFacet from "./components/facets/SpeciesFacet";
import TextureFacet from "./components/facets/TextureFacet";
import ThicknessFacet from "./components/facets/ThicknessFacet";
import StyleFacet from "./components/facets/StyleFacet";
import ShadeFacet from "./components/facets/ShadeFacet";
import ShapeFacet from "./components/facets/ShapeFacet";
import LookFacet from "./components/facets/LookFacet";
import FiberFacet from "./components/facets/FiberFacet";
import BackingFacet from "./components/facets/BackingFacet";
import HeightFacet from "./components/facets/HeightFacet";
import WidthFacet from "./components/facets/WidthFacet";
import ResetFilters from "./components/facets/ResetFilters";
import PerPage from "./components/facets/PerPage";
import Pagination from "./components/Pagination";
import Loader from "./components/Loader";
import { useNavigate } from "react-router-dom";
import RemoveFilters from "./components/RemoveFilters";

function Products() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const wpProductFilter = window.wpProductFilter;
  const wpProductCategory = window.wpProductCategory;

  const catColor = wpProductCategory.color_facet
    ? wpProductCategory.color_facet.toLowerCase()
    : "";
  const catBrandFacet = wpProductCategory.brand_facet
    ? wpProductCategory.brand_facet.toLowerCase()
    : "";
  const catCollectionFacet = wpProductCategory.collection_facet
    ? wpProductCategory.collection_facet.toLowerCase()
    : "";
  const catStyleFacet = wpProductCategory.style_facet
    ? wpProductCategory.style_facet.toLowerCase()
    : "";
  const catShadeFacet = wpProductCategory.shade_facet
    ? wpProductCategory.shade_facet.toLowerCase()
    : "";
  const catShapeFacet = wpProductCategory.shape_facet
    ? wpProductCategory.shape_facet.toLowerCase()
    : "";
  const catFiberFacet = wpProductCategory.fiber
    ? wpProductCategory.fiber.toLowerCase()
    : "";
  const shortcodeLoadFacets = {
    color_facet: catColor,
    brand_facet: catBrandFacet,
    collection_facet: catCollectionFacet,
    style_facet: catStyleFacet,
    shade_facet: catShadeFacet,
    shape_facet: catShapeFacet,
    fiber: catFiberFacet,
  };

  let shortcodeFacets = Object.fromEntries(
    Object.entries(shortcodeLoadFacets).filter(([_, v]) => v != "")
  );

  const queryParams = new URLSearchParams(window.location.search);

  const paramColor = queryParams.get("color_facet")
    ? queryParams.get("color_facet").toLowerCase()
    : "";
  const paramBrandFacet = queryParams.get("brand_facet")
    ? queryParams.get("brand_facet").toLowerCase()
    : "";
  const paramCollectionFacet = queryParams.get("collection_facet")
    ? queryParams.get("collection_facet").toLowerCase()
    : "";
  const paramApplicationFacet = queryParams.get("application_facet")
    ? queryParams.get("application_facet").toLowerCase()
    : "";
  const paramVariationFacet = queryParams.get("color_variation_facet")
    ? queryParams.get("color_variation_facet").toLowerCase()
    : "";
  const paramConstructionFacet = queryParams.get("construction_facet")
    ? queryParams.get("construction_facet").toLowerCase()
    : "";
  const paramInstallationFacet = queryParams.get("installation_facet")
    ? queryParams.get("installation_facet").toLowerCase()
    : "";
  const paramLocationFacet = queryParams.get("location_facet")
    ? queryParams.get("location_facet").toLowerCase()
    : "";
  const paramSpeciesFacet = queryParams.get("species_facet")
    ? queryParams.get("species_facet").toLowerCase()
    : "";
  const paramTextureFacet = queryParams.get("surface_texture_facet")
    ? queryParams.get("surface_texture_facet").toLowerCase()
    : "";
  const paramStyleFacet = queryParams.get("style_facet")
    ? queryParams.get("style_facet").toLowerCase()
    : "";
  const paramShadeFacet = queryParams.get("shade_facet")
    ? queryParams.get("shade_facet").toLowerCase()
    : "";
  const paramShapeFacet = queryParams.get("shape_facet")
    ? queryParams.get("shape_facet").toLowerCase()
    : "";
  const paramLookFacet = queryParams.get("look_facet")
    ? queryParams.get("look_facet").toLowerCase()
    : "";
  const paramFiberFacet = queryParams.get("fiber")
    ? queryParams.get("fiber").toLowerCase()
    : "";
  const paramBackingFacet = queryParams.get("backing_facet")
    ? queryParams.get("backing_facet").toLowerCase()
    : "";
  const paramThicknessFacet = queryParams.get("thickness_facet")
    ? queryParams.get("thickness_facet").toLowerCase()
    : "";
  const paramHeight = queryParams.get("height")
    ? queryParams.get("height").toLowerCase()
    : "";
  const paramWidth = queryParams.get("width")
    ? queryParams.get("width").toLowerCase()
    : "";

  const paramSampleFacet = queryParams.get("sample_available_facet")
    ? queryParams.get("sample_available_facet").toLowerCase()
    : "";

  const filterObj = {
    color_facet: paramColor ? paramColor : catColor,
    brand_facet: paramBrandFacet ? paramBrandFacet : catBrandFacet,
    collection_facet: paramCollectionFacet
      ? paramCollectionFacet
      : catCollectionFacet,
    application_facet: paramApplicationFacet,
    color_variation_facet: paramVariationFacet,
    construction_facet: paramConstructionFacet,
    installation_facet: paramInstallationFacet,
    location_facet: paramLocationFacet,
    species_facet: paramSpeciesFacet,
    surface_texture_facet: paramTextureFacet,
    style_facet: paramStyleFacet ? paramStyleFacet : catStyleFacet,
    shade_facet: paramShadeFacet ? paramShadeFacet : catShadeFacet,
    shape_facet: paramShapeFacet ? paramShapeFacet : catShapeFacet,
    look_facet: paramLookFacet,
    fiber: paramFiberFacet ? paramFiberFacet : catFiberFacet,
    backing_facet: paramBackingFacet,
    thickness_facet: paramThicknessFacet,
    height: paramHeight,
    width: paramWidth,
    sample_available_facet: paramSampleFacet,
    search: queryParams.get("search")
      ? queryParams.get("search").toLowerCase()
      : "",
  };
  let filteredObj = Object.fromEntries(
    Object.entries(filterObj).filter(([_, v]) => v != "")
  );

  const [filters, setFilters] = useState(filteredObj);

  const filterObj1 = {
    color_facet: paramColor,
    brand_facet: paramBrandFacet,
    collection_facet: paramCollectionFacet,
    application_facet: paramApplicationFacet,
    color_variation_facet: paramVariationFacet,
    construction_facet: paramConstructionFacet,
    installation_facet: paramInstallationFacet,
    location_facet: paramLocationFacet,
    species_facet: paramSpeciesFacet,
    surface_texture_facet: paramTextureFacet,
    style_facet: paramStyleFacet,
    shade_facet: paramShadeFacet,
    shape_facet: paramShapeFacet,
    look_facet: paramLookFacet,
    fiber: paramFiberFacet,
    backing_facet: paramBackingFacet,
    thickness_facet: paramThicknessFacet,
    height: paramHeight,
    width: paramWidth,
    sample_available_facet: paramSampleFacet,
    paged: queryParams.get("paged") ? queryParams.get("paged") : "",
    limit: queryParams.get("limit") ? queryParams.get("limit") : "",
    search: queryParams.get("search")
      ? queryParams.get("search").toLowerCase()
      : "",
  };
  let filteredObj1 = Object.fromEntries(
    Object.entries(filterObj1).filter(([_, v]) => v != "")
  );
  const [urlParams, setUrlParams] = useState(filteredObj1);
  // const [sort, setSort] = useState("");
  const paramPage = queryParams.get("paged") ? queryParams.get("paged") : 1;
  const [paged, setPage] = useState(paramPage);
  const [productCollections, setCollections] = useState({});
  const [productBrands, setBrands] = useState({});
  const [productColors, setColors] = useState({});
  const [productApplications, setApplications] = useState({});
  const [productConstructions, setConstruction] = useState({});
  const [productColorVariations, setColorVariation] = useState({});
  const [productInstallationMethods, setInstallationMethods] = useState({});
  const [productLocations, setLocations] = useState({});
  const [productSpecies, setSpecies] = useState({});
  const [productTextures, setTextures] = useState({});
  const [productStyle, setStyle] = useState({});
  const [productShade, setShade] = useState({});
  const [productShape, setShape] = useState({});
  const [productLook, setLook] = useState({});
  const [productFiber, setFiber] = useState({});
  const [productBacking, setBacking] = useState({});
  const [productThickness, setThickness] = useState({});
  const [productHeight, setHeight] = useState({});
  const [productWidth, setWidth] = useState({});
  const [productSamples, setSamples] = useState({});

  const [pageNumbers, setPageNumbers] = useState([]);

  const paramLimit = queryParams.get("limit") ? queryParams.get("limit") : 12;
  const [itemsPerPage, setItemsPerPage] = useState(paramLimit);

  const paramSearch = queryParams.get("search")
    ? queryParams.get("search").toLowerCase()
    : "";
  const [searchText, setSearchText] = useState(paramSearch);

  const inStock = wpProductCategory.inStock ? wpProductCategory.inStock : "";
  const plpTitle = wpProductCategory.title ? wpProductCategory.title : "";
  const plpFacets = wpProductCategory.plpFacets
    ? wpProductCategory.plpFacets
    : [];
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchProducts();
  }, [filters, paged, itemsPerPage]);

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      category: wpProductCategory.category,
      ...filters,
      paged,
      limit: itemsPerPage,
      in_stock: inStock,
    });

    const query1 = new URLSearchParams({
      ...urlParams,
    });

    try {
      navigate("?" + query1.toString());
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      setProducts(data.products_group_by);
      setTotalProducts(data.total);
      populateFilters(data.filter_counts);
      calculatePagination(data.total, itemsPerPage, paged);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleFlyer = () => {
    setIsOpen(!isOpen);
  };

  const populateFilters = (counts) => {
    setCollections(counts["collection_facet"]);
    setBrands(counts["brand_facet"]);
    setColors(counts["color_facet"]);
    setApplications(counts["application_facet"]);
    setColorVariation(counts["color_variation_facet"]);
    setConstruction(counts["construction_facet"]);
    setInstallationMethods(counts["installation_facet"]);
    setLocations(counts["location_facet"]);
    setSpecies(counts["species_facet"]);
    setTextures(counts["surface_texture_facet"]);
    setStyle(counts["style_facet"]);
    setShade(counts["shade_facet"]);
    setShape(counts["shape_facet"]);
    setLook(counts["look_facet"]);
    setFiber(counts["fiber"]);
    setBacking(counts["backing_facet"]);
    setHeight(counts["height"]);
    setWidth(counts["width"]);
    setThickness(counts["thickness_facet"]);
    setSamples(counts["sample_available_facet"]);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({
      ...shortcodeFacets,
      ...filters,
      [filterName]: value.toLowerCase(),
    });
    if (filterName == "search") {
      setSearchText(value.toLowerCase());
    }
    setPage(1);
    setUrlParams({
      ...urlParams,
      [filterName]: value.toLowerCase(),
      paged: 1,
    });
  };
  const handleFilterClick = (filterName, value) => {
    setFilters({
      ...shortcodeFacets,
      ...filters,
      [filterName]: value.toLowerCase(),
    });

    setPage(1);

    setUrlParams({
      ...urlParams,
      [filterName]: value.toLowerCase(),
      paged: 1,
    });
  };
  const handleRemoveFilter = (filterName) => {
    const updatedObj = Object.fromEntries(
      Object.entries(filters).filter(([key]) => key !== filterName)
    );

    setFilters({
      ...updatedObj,
      ...shortcodeFacets,
    });
    setPage(1);
    setUrlParams({
      ...updatedObj,
      ...shortcodeFacets,
      paged,
      limit: itemsPerPage,
    });
  };
  const handleResetFilter = () => {
    setFilters({
      ...shortcodeFacets,
    });
    setSearchText("");
    setPage(1);
    setUrlParams({});
  };
  const handlePerPage = (value) => {
    setItemsPerPage(value);
    // handleFilterChange("limit", value);
    setPage(1);
    setUrlParams({
      ...urlParams,
      limit: value,
    });
  };

  const handlePagination = (pageNo) => {
    setPage(pageNo.target.value);
    setUrlParams({
      ...urlParams,
      paged: pageNo.target.value,
    });
  };
  const calculatePagination = (totalProducts, itemsPerPage, page) => {
    const total_pages = Math.ceil(totalProducts / itemsPerPage);
    let start_page = 1;
    let end_page = total_pages;
    let numbers = [start_page, end_page];
    if (total_pages === 1) {
      numbers = [1];
    } else if (total_pages === 2) {
      numbers = [1, 2];
    } else if (total_pages === 3) {
      numbers = [1, 2, 3];
    } else if (page < 3 || page == end_page || page == end_page - 1) {
      numbers = [1, 2, 3, end_page - 2, end_page - 1, end_page];
    } else if (page >= 3 && page <= end_page - 2) {
      let current_page = page;
      numbers = [
        1,
        Number(current_page) - 1,
        current_page,
        Number(current_page) + 1,
        Number(current_page) + 2,
        end_page - 1,
        end_page,
      ];
    }

    setPageNumbers([...new Set(numbers)]);
  };

  const hasActiveFilters = Object.values(filters).some((value) => value !== "");

  return (
    <>
      <div className="product-list-container">
        {loading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <h4 className="fl-heading plp-heading" role="heading">
                    <span class="fl-heading-text">{plpTitle}</span>
                  </h4>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12 top-pager">
                  <div className="text-left">
                    {totalProducts > 0 ? totalProducts + " Results" : ""}
                  </div>
                  <div className="text-right">
                    <PerPage handlePerPage={handlePerPage} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-lg-3 col-md-3 col-sm-4 facet_filters ${
                    isOpen ? "open" : ""
                  }`}>
                  <div className="close_bar">
                    <a
                      className="close close_sidebar"
                      onClick={toggleFlyer}
                      href="javascript:void(0)">
                      <i className="fa fa-close">&nbsp;</i>
                    </a>
                  </div>
                  {plpFacets.includes("search") && (
                    <div class="facet_wrap">
                      <strong>Search</strong>
                      <div className="facetwp-facet">
                        <input
                          type="text"
                          className="search_input"
                          placeholder="Search Keyword"
                          value={searchText}
                          onChange={(e) => {
                            handleFilterChange("search", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {hasActiveFilters ? (
                    <ResetFilters handleResetFilter={handleResetFilter} />
                  ) : (
                    ""
                  )}
                  {hasActiveFilters ? (
                    <RemoveFilters
                      filters={filters}
                      handleRemoveFilter={handleRemoveFilter}
                    />
                  ) : (
                    ""
                  )}
                  {plpFacets.includes("brand_facet") &&
                  Object.keys(productBrands).length > 0 ? (
                    <BrandFacet
                      handleFilterClick={handleFilterClick}
                      productBrands={productBrands}
                    />
                  ) : (
                    ""
                  )}
                  {plpFacets.includes("collection_facet") &&
                  Object.keys(productCollections).length > 0 ? (
                    <CollectionFacet
                      handleFilterClick={handleFilterClick}
                      productCollections={productCollections}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("application_facet") &&
                  Object.keys(productApplications).length > 0 ? (
                    <ApplicationFacet
                      handleFilterClick={handleFilterClick}
                      productApplications={productApplications}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("style_facet") &&
                  Object.keys(productStyle).length > 0 ? (
                    <StyleFacet
                      handleFilterClick={handleFilterClick}
                      productStyle={productStyle}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("color_variation_facet") &&
                  Object.keys(productColorVariations).length > 0 ? (
                    <ColorVariationFacet
                      handleFilterClick={handleFilterClick}
                      productColorVariations={productColorVariations}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("construction_facet") &&
                  Object.keys(productConstructions).length > 0 ? (
                    <ConstructionFacet
                      handleFilterClick={handleFilterClick}
                      productConstructions={productConstructions}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("installation_method") &&
                  Object.keys(productInstallationMethods).length > 0 ? (
                    <InstallationMethodFacet
                      handleFilterClick={handleFilterClick}
                      productInstallationMethods={productInstallationMethods}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("location_facet") &&
                  Object.keys(productLocations).length > 0 ? (
                    <LocationFacet
                      handleFilterClick={handleFilterClick}
                      productLocations={productLocations}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("species_facet") &&
                  Object.keys(productSpecies).length > 0 ? (
                    <SpeciesFacet
                      handleFilterClick={handleFilterClick}
                      productSpecies={productSpecies}
                    />
                  ) : (
                    ""
                  )}
                  {plpFacets.includes("look_facet") &&
                  Object.keys(productLook).length > 0 ? (
                    <LookFacet
                      handleFilterClick={handleFilterClick}
                      productLook={productLook}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("fiber") &&
                  Object.keys(productFiber).length > 0 ? (
                    <FiberFacet
                      handleFilterClick={handleFilterClick}
                      productFiber={productFiber}
                    />
                  ) : (
                    ""
                  )}
                  {plpFacets.includes("backing_facet") &&
                  Object.keys(productBacking).length > 0 ? (
                    <BackingFacet
                      handleFilterClick={handleFilterClick}
                      productBacking={productBacking}
                    />
                  ) : (
                    ""
                  )}
                  {plpFacets.includes("surface_texture_facet") &&
                  Object.keys(productTextures).length > 0 ? (
                    <TextureFacet
                      handleFilterClick={handleFilterClick}
                      productTextures={productTextures}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("shade_facet") &&
                  Object.keys(productShade).length > 0 ? (
                    <ShadeFacet
                      handleFilterClick={handleFilterClick}
                      productShade={productShade}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("shape_facet") &&
                  Object.keys(productShape).length > 0 ? (
                    <ShapeFacet
                      handleFilterClick={handleFilterClick}
                      productShape={productShape}
                    />
                  ) : (
                    ""
                  )}
                  {plpFacets.includes("color_facet") &&
                  Object.keys(productColors).length > 0 ? (
                    <ColorFacet
                      handleFilterClick={handleFilterClick}
                      productColors={productColors}
                    />
                  ) : (
                    ""
                  )}
                  {plpFacets.includes("thickness_facet") &&
                  Object.keys(productThickness).length > 0 ? (
                    <ThicknessFacet
                      handleFilterClick={handleFilterClick}
                      productThickness={productThickness}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("height") &&
                  Object.keys(productHeight).length > 0 ? (
                    <HeightFacet
                      handleFilterClick={handleFilterClick}
                      productHeight={productHeight}
                    />
                  ) : (
                    ""
                  )}

                  {plpFacets.includes("width") &&
                  Object.keys(productWidth).length > 0 ? (
                    <WidthFacet
                      handleFilterClick={handleFilterClick}
                      productWidth={productWidth}
                    />
                  ) : (
                    ""
                  )}

                  <div className="close_sidebar_button">
                    <a
                      className="fl-button close_sidebar"
                      onClick={toggleFlyer}
                      href="javascript:void(0)">
                      <span className="fl-button-text">View Results</span>
                    </a>
                  </div>
                </div>
                {Object.keys(products).length > 0 && (
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 products-list ">
                        <div class="open_sidebar">
                          <a
                            class="fl-button"
                            onClick={toggleFlyer}
                            href="javascript:void(0)">
                            <span class="fl-button-text">Filter By</span>
                          </a>
                        </div>
                        <div className="product-plp-grid product-grid swatch facetwp-template1">
                          <div className="row product-row">
                            {Object.keys(products).map((product, i) => {
                              return (
                                <ProductLoop
                                  key={i}
                                  products={products[product]}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 bottom-pager">
                        <div className="page-info">
                          <div className="pagination_wrapper">
                            <ul>
                              {pageNumbers.map((number) => {
                                return (
                                  <Pagination
                                    key={number}
                                    number={number}
                                    handlePagination={handlePagination}
                                    currentPage={paged}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {Object.keys(products).length == 0 && loading == false && (
                  <div className="col-lg-9 col-md-9 col-sm-12 product-list-container">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <h2>No Products Available</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Products;
