import React from "react";
import shaw_logo from "./BrandLogo/shaw_logo.jpg";
import anderson_tuftex_logo from "./BrandLogo/anderson_tuftex_logo.jpg";
import karastan_logo from "./BrandLogo/karastan_logo.jpg";
import armstrong_logo from "./BrandLogo/armstrong_logo.jpg";
import dreamweaver_logo from "./BrandLogo/dreamweaver_logo.jpg";
import philadelphia_commercial_logo from "./BrandLogo/philadelphia_commercial_logo.jpg";
import coretec_logo from "./BrandLogo/coretec_logo.jpg";
import daltile_logo from "./BrandLogo/daltile_logo.jpg";
import mohawk_logo from "./BrandLogo/mohawk_logo.jpg";
import bruce_logo from "./BrandLogo/bruce_logo.jpg";
import amrican_olean_logo from "./BrandLogo/amrican_olean_logo.jpg";
import mannington_logo from "./BrandLogo/mannington_logo.jpg";
import fabrica from "./BrandLogo/fabrica.jpg";
import masland from "./BrandLogo/masland.jpg";
import dixie from "./BrandLogo/dixie-home.jpg";
import nourison_logo from "./BrandLogo/nourison_logo.jpg";
import hydraluxe_logo from "./BrandLogo/hydraluxe_logo.jpg";
import karndean_logo from "./BrandLogo/karndean_logo.jpg";
import cali_logo from "./BrandLogo/cali_logo.jpg";
import robbins from "./BrandLogo/robbins.jpg";
import mercier from "./BrandLogo/mercier.jpg";
import hallmark from "./BrandLogo/hallmark.jpg";
import bella_cera from "./BrandLogo/bella_cera.jpg";
import godfrey_hirst from "./BrandLogo/godfrey_hirst.jpg";
import emsertile_logo from "./BrandLogo/emsertile_logo.jpg";
import marazzi from "./BrandLogo/marazzi.jpg";
import happy_feet from "./BrandLogo/happy_feet.jpg";
import happy_floors from "./BrandLogo/happy_floors.jpg";
import somerset from "./BrandLogo/somerset.jpg";
import naturally_aged_flooring from "./BrandLogo/naturally_aged_flooring.jpg";
import msi_tile from "./BrandLogo/msi_tile.jpg";
import stanton from "./BrandLogo/stanton.jpg";

function ProductBrandLogos({ product }) {
  return (
    <div>
      {product.brand_facet == "Shaw Floors" ? (
        <img src={shaw_logo} alt="Shaw Logo" />
      ) : product.brand_facet == "Anderson Tuftex" ? (
        <img src={anderson_tuftex_logo} alt="Anderson Tuftex" />
      ) : product.brand_facet == "Karastan" ? (
        <img src={karastan_logo} alt="Karastan" />
      ) : product.brand_facet == "Armstrong" ? (
        <img src={armstrong_logo} alt="Armstrong" />
      ) : product.brand_facet == "Dreamweaver" ? (
        <img src={dreamweaver_logo} alt="Dream Weaver" />
      ) : product.brand_facet == "Philadelphia Commercial" ? (
        <img src={philadelphia_commercial_logo} alt="Philadelphia Commercial" />
      ) : product.brand_facet == "COREtec" ? (
        <img src={coretec_logo} alt="COREtec" />
      ) : product.brand_facet == "Daltile" ? (
        <img src={daltile_logo} alt="Daltile" />
      ) : product.brand_facet == "Mohawk" ? (
        <img src={mohawk_logo} alt="Mohawk" />
      ) : product.brand_facet == "Bruce" ? (
        <img src={bruce_logo} alt="Bruce" />
      ) : product.brand_facet == "American Olean" ? (
        <img src={amrican_olean_logo} alt="American Olean" />
      ) : product.brand_facet == "Mannington" ? (
        <img src={mannington_logo} alt="Mannington" />
      ) : product.brand_facet == "Fabrica" ? (
        <img src={fabrica} alt="Fabrica" />
      ) : product.brand_facet == "Masland" ? (
        <img src={masland} alt="Masland" />
      ) : product.brand_facet == "Dixie Home" ? (
        <img src={dixie} alt="Dixie Home" />
      ) : product.brand_facet == "Nourison" ? (
        <img src={nourison_logo} alt="Nourison" />
      ) : product.brand_facet == "Hydraluxe" ? (
        <img src={hydraluxe_logo} alt="Hydraluxe" />
      ) : product.brand_facet == "Karndean" ? (
        <img src={karndean_logo} alt="Karndean" />
      ) : product.brand_facet == "Cali" ? (
        <img src={cali_logo} alt="Cali" />
      ) : product.brand_facet == "Robbins" ? (
        <img src={robbins} alt="Robbins" />
      ) : product.brand_facet == "Mercier" ? (
        <img src={mercier} alt="Mercier" />
      ) : product.brand_facet == "Hallmark" ? (
        <img src={hallmark} alt="Hallmark" />
      ) : product.brand_facet == "Bella Cera" ? (
        <img src={bella_cera} alt="Bella Cera" />
      ) : product.brand_facet == "Godfrey Hirst" ? (
        <img src={godfrey_hirst} alt="Godfrey Hirst" />
      ) : product.brand_facet == "Stanton" ? (
        <img src={stanton} alt="Stanton" />
      ) : product.brand_facet == "Emser" ? (
        <img src={emsertile_logo} alt="Emser" />
      ) : product.brand_facet == "Marazzi" ? (
        <img src={marazzi} alt="Marazzi" />
      ) : product.brand_facet == "Happy Feet" ? (
        <img src={happy_feet} alt="Happy Feet" />
      ) : product.brand_facet == "Happy Floors" ? (
        <img src={happy_floors} alt="Happy Floors" />
      ) : product.brand_facet == "Somerset" ? (
        <img src={somerset} alt="Somerset" />
      ) : product.brand_facet == "Naturally Aged Flooring" ? (
        <img src={naturally_aged_flooring} alt="Naturally Aged Flooring" />
      ) : product.brand_facet == "MSI Tile" ? (
        <img src={msi_tile} alt="MSI Tile" />
      ) : (
        <p>{product.brand_facet}</p>
      )}
    </div>
  );
}

export default ProductBrandLogos;
